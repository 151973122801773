import { ReactElement } from 'react'
import { useModalContext } from '../../contexts/modalContext'
import { Button, ButtonSize } from '@forte-fit/forte-storybook'
import { Modal } from '../global/Modal'

interface Props {
  userId: string
  callback: (userId: string) => void
}

export function ConfirmSuspendDialog({ userId, callback }: Props): ReactElement {
  const { closeModal } = useModalContext()

  function onSave() {
    callback(userId)
    closeModal()
  }

  function onCancel() {
    closeModal()
  }

  return (
    <Modal title="Suspend User" closeModal={closeModal} small={true}>
      <div className="flex-1">
        <div className="flex-1 text-black-50 mt-1">
          Are you sure you want to suspend user? User will not be able to log in while suspended.
        </div>
        <div className="flex items-end justify-start space-x-2 mt-5 font-sans">
          <Button onClick={onSave}>Suspend</Button>
          <Button size={ButtonSize.Small} onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}
