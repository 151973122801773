import { ReactElement } from 'react'

interface Props {
  id: string
  label: string
  name: string
  autoComplete?: string
  disabled?: boolean
  placeholder?: string
  type?: string
  value?: string
  onChange: (value: string) => void
}

export function HorizontalInput({
  id,
  label,
  name,
  autoComplete = 'off',
  disabled = false,
  placeholder,
  type = 'text',
  value,
  onChange,
}: Props): ReactElement {
  return (
    <div className="grid grid-cols-2 py-3 flex-start shadow-[0_2px_0_rgba(0,0,0,0.05)] hover:shadow-[0_2px_0_rgba(0,0,0,0.50)] focus-within:shadow-[0_2px_0_rgba(0,0,0,1.0)]">
      <label className="flex grow-1 text-black-50 text-sm mr-2 items-center">{label}</label>
      <input
        name={name}
        id={id}
        type={type}
        value={value}
        autoComplete={autoComplete}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        className={`flex grow-1 ${
          disabled ? 'text-black-50' : 'text-black-100'
        } text-sm items-start v-full border-none focus:ring-0`}
      />
    </div>
  )
}
