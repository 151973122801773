import { ReactElement, useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ChevronDownIcon } from '@heroicons/react/outline'

interface Props {
  id: string
  items: { label: string; value: string }[]
  label: string
  value?: string
  onChange: (value: string) => void
}

export function HorizontalDropdown({ id, items, label, value, onChange }: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  const filterRef = useRef(null)
  useOnClickOutside(filterRef, () => setIsOpen(false))

  useEffect(() => {
    setIsOpen(false)
  }, [items])

  return (
    <div className="grid grid-cols-2 py-3 flex-start shadow-[0_2px_0_rgba(0,0,0,0.05)] hover:shadow-[0_2px_0_rgba(0,0,0,0.50)] focus-within:shadow-[0_2px_0_rgba(0,0,0,1.0)]">
      <label className="flex grow-1 text-black-50 text-sm mr-2 items-center">{label}</label>
      <div
        ref={filterRef}
        id={id}
        className="flex grow-1 relative items-center justify-between text-sm cursor-pointer px-3 py-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        {value && value.length > 0 ? value : `Select ${label}`}
        <ChevronDownIcon className="ml-2 h-4 w-4 text-black-60" />
        {isOpen && (
          <div className="absolute top-11 right-0 bg-white z-10 rounded-lg w-48 drop-shadow-xl">
            {items.map((item, index) => (
              <button
                key={index}
                value={item.value}
                className="px-4 py-3 hover:bg-black-5 bg-white w-full text-left text-sm truncate cursor-pointer"
                onClick={(e) => onChange((e.target as HTMLElement).innerText)}
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
