import { ReactElement } from 'react'
import { ForteH1, Button } from '@forte-fit/forte-storybook'
import { MenuAlt2Icon } from '@heroicons/react/outline'
import { GLOBAL_ADMIN } from '../../constants/common'

interface Props {
  userGroup: string
  openPartnerDialog: () => void
}

export function PartnerHeader({ userGroup, openPartnerDialog }: Props): ReactElement {
  return (
    <div className="sticky top-0 z-10 flex flex-shrink-0">
      <button
        type="button"
        className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
      </button>
      <div className="flex justify-between flex-1 px-4 bg-white md:px-0">
        <div className="flex flex-1 py-6">
          <ForteH1>Partners</ForteH1>
        </div>
        {userGroup === GLOBAL_ADMIN && (
          <div className="flex items-center ml-4 md:ml-6 font-sans">
            <Button onClick={openPartnerDialog}>Partner</Button>
          </div>
        )}
      </div>
    </div>
  )
}
