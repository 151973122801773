import { ReactElement, KeyboardEvent, useEffect, useState, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../contexts/authContext'
import { useToastContext } from '../contexts/toastContext'
import { AccessTokenRequest } from '../models/authentication'
import { ForteH3 } from '@forte-fit/forte-storybook'
import { LoginButton } from '../components/login/LoginButton'
import { VerticalInput } from '../components/global/VerticalInput'
import forteLogo from '../assets/forte-logo.svg'
import { TOAST_INTERVAL_LOGIN_ERROR, REGEX_EMAIL_VALIDATION } from '../constants/common'
import { sendResetPasswordEmailRequest } from '../services/users'

export function LoginPage(): ReactElement {
  const navigate = useNavigate()
  const { authorized, authInProgress, error, authorize } = useAuthContext()
  const toast = useToastContext()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginButtonEnabled, setLoginButtonEnabled] = useState(false)
  const [emailError, setEmailError] = useState('')

  useEffect(() => {
    if (authorized) {
      navigate('/users')
    }
  }, [authorized])

  useEffect(() => {
    if (error) {
      toast.pushError('Your email or password are incorrect', TOAST_INTERVAL_LOGIN_ERROR)
    }
  }, [error])

  useEffect(() => {
    if (shouldSignInBeEnabled()) {
      setLoginButtonEnabled(true)
      setEmailError('')
    } else {
      setLoginButtonEnabled(false)
    }
  }, [email, password])

  function onSubmit() {
    const authData: AccessTokenRequest = {
      grant_type: 'password',
      username: email,
      password: password,
    }
    authorize(authData)
  }

  function shouldSignInBeEnabled(): boolean {
    return password.length > 0 && email.length > 0 && REGEX_EMAIL_VALIDATION.test(email)
  }

  async function sendResetPasswordEmail(e: MouseEvent<HTMLAnchorElement>): Promise<void> {
    e.preventDefault()
    if (emailError) {
      return
    }

    const url = `${window.location.origin}/reset-password`
    await sendResetPasswordEmailRequest(email, url)
      .then(() => toast.pushSuccess(`Reset password link was sent to ${email}`))
      .catch((requestError) => {
        toast.pushError(requestError.response.data.message)
      })
  }

  function onPressEnter(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      if (shouldSignInBeEnabled()) {
        onSubmit()
      }
    }
  }

  function onEmailBlur() {
    setEmailError(!REGEX_EMAIL_VALIDATION.test(email) && email.length > 0 ? 'Please provide a valid email address' : '')
  }

  return (
    <div className="h-screen flex flex-wrap flex-row place-content-center">
      <div className="basis-80">
        <div className="mb-10 justify-center">
          <img className="mx-auto w-48" src={forteLogo} />
        </div>
        <div className="flex mb-10 place-content-center">
          <ForteH3>Partner Management</ForteH3>
        </div>
        <div className="m-3">
          <VerticalInput
            label="Email"
            name="email"
            id="email"
            value={email}
            type="email"
            autoComplete="username"
            placeholder="Email"
            error={emailError}
            onChange={setEmail}
            onKeyDown={onPressEnter}
            onBlur={onEmailBlur}
          />
        </div>
        <div className="m-3">
          <VerticalInput
            label="Password"
            name="password"
            id="password"
            value={password}
            type="password"
            autoComplete="current-password"
            placeholder="Password"
            onChange={setPassword}
            onKeyDown={onPressEnter}
          />
        </div>
        <div className="m-3">
          <a href="/" onClick={sendResetPasswordEmail} style={{ color: '#2d80d4' }}>
            Forgot password
          </a>
        </div>
        <div className="mt-5 ml-3 mr-3">
          <LoginButton
            onClick={onSubmit}
            buttonLabel="Sign In"
            disabled={!loginButtonEnabled || authInProgress}
            isLoading={authInProgress}
          />
        </div>
      </div>
    </div>
  )
}
