import { ReactElement, ReactNode } from 'react'
import { ForteH3 } from '@forte-fit/forte-storybook'

interface Props {
  children: ReactNode
  title?: string
  small?: boolean
  closeModal: () => void
}

export function Modal({ children, title = '', small = false, closeModal }: Props): ReactElement {
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      closeModal()
    }
  })

  return (
    <div
      className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black-50 ${
        title.length <= 0 ? 'invisible' : 'visible'
      }`}
    >
      <div className={`${small ? 'w-96' : 'w-1/2'} max-w-3xl`}>
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white p-5">
          <div className="flex items-center justify-between">
            <ForteH3>{title}</ForteH3>
            <button className="bg-transparent border-0 float-right text-3xl" onClick={closeModal}>
              ×
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
