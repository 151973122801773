import { ReactElement, useEffect, useState } from 'react'
import { useModalContext } from '../contexts/modalContext'
import { useToastContext } from '../contexts/toastContext'
import { AdminLayout } from './AdminLayout'
import { ConfirmSuspendDialog } from '../components/users/ConfirmSuspendDialog'
import { ErrorDialog } from '../components/global/ErrorDialog'
import { ExportToCsv } from 'export-to-csv-fix-source-map'
import { FilterDropdown } from '../components/global/FilterDropdown'
import { ItemsLabel } from '../components/global/ItemsLabel'
import { LoadingIndicator } from '../components/global/LoadingIndicator'
import { Pagination } from '../components/global/Pagination'
import { PartnerListResponse } from '../models/partners'
import { SearchInput } from '../components/global/SearchInput'
import { UserDialog } from '../components/users/UserDialog'
import { UserHeader } from '../components/users/UserHeader'
import { UserResponse, UserTableData } from '../models/users'
import { UserTable } from '../components/users/UserTable'
import { errorHandler, formatUsersForExport } from '../utils/helpers'
import { getPartnerList } from '../services/partners'
import { getUsersBySourcePartnerId, resetUserPassword, terminateUser } from '../services/users'
import {
  GLOBAL_ADMIN,
  LocalStorageItem,
  PARTNER_ID_FOR_ALL_USERS,
  USERS_PER_PAGE,
  UserStatus,
} from '../constants/common'

export function UsersPage(): ReactElement {
  const { modal, showModal } = useModalContext()
  const toast = useToastContext()

  const [users, setUsers] = useState<UserResponse[]>([])
  const [partnerId, setPartnerId] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)
  const [refreshUsers, setRefreshUsers] = useState(false)
  const [changeInProgress, setChangeInProgress] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [userGroup, setUserGroup] = useState('')
  const [partnerList, setPartnerList] = useState<PartnerListResponse[]>([])
  const [partnerFilter, setPartnerFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState(UserStatus.Unknown)

  const actions = [
    { label: 'Edit', callback: editUser },
    { label: 'Reset Password', callback: resetPassword },
  ]

  const userData: UserTableData[] = users.map((user) => {
    return {
      id: user.Id,
      name: `${user.FirstName} ${user.LastName}`,
      email: user.Email,
      partner: user.OrganizationName,
      active: user.Active,
      actions: user.Active ? [...actions, { label: 'Suspend', callback: confirmSuspendUser }] : actions,
    }
  })

  useEffect(() => {
    getPartnerList().then(setPartnerList).catch(errorHandler)
    setPartnerId(localStorage.getItem(LocalStorageItem.PartnerId) || '')
    setUserGroup(localStorage.getItem(LocalStorageItem.Scope) || '')
  }, [])

  useEffect(() => {
    if (partnerId && userGroup) {
      getUsers()
    }
  }, [partnerId, currentPage, userGroup])

  useEffect(() => {
    if (refreshUsers) {
      getUsers()
      setRefreshUsers(false)
    }
  }, [refreshUsers])

  useEffect(() => {
    if (partnerId.length > 0) {
      setCurrentPage(0)
      getUsers()
    }
  }, [searchTerm, partnerFilter, statusFilter])

  function getUsers() {
    setChangeInProgress(true)

    let partnerIdForApiCall = partnerId
    if (userGroup === GLOBAL_ADMIN) {
      partnerIdForApiCall = PARTNER_ID_FOR_ALL_USERS
      if (partnerFilter.length > 0) {
        partnerIdForApiCall = partnerList.find((element) => element.OrganizationName === partnerFilter)?.Id || ''
      }
    }

    getUsersBySourcePartnerId(partnerIdForApiCall, USERS_PER_PAGE, currentPage, searchTerm, statusFilter)
      .then((response) => {
        setUsers(response)
        if (response?.length > 0) {
          setTotalUsers(response[0].OverallCount)
        } else {
          setTotalUsers(0)
        }
      })
      .catch((error) => {
        errorHandler(error)
        showModal(<ErrorDialog title="Error. Please try again later." message={error.toString()} />)
      })
      .finally(() => setChangeInProgress(false))
  }

  function openUserDialog() {
    showModal(
      <UserDialog
        setRefreshUsers={setRefreshUsers}
        isGlobalAdmin={userGroup === GLOBAL_ADMIN}
        showError={displayDialogError}
      />
    )
  }

  function editUser(userId: string) {
    showModal(
      <UserDialog
        userId={userId}
        setRefreshUsers={setRefreshUsers}
        isGlobalAdmin={userGroup === GLOBAL_ADMIN}
        showError={displayDialogError}
      />
    )
  }

  function resetPassword(userId: string) {
    resetUserPassword(userId)
      .then(() => {
        toast.pushSuccess('A reset password link has been sent.')
      })
      .catch((error) => {
        errorHandler(error)
        toast.pushError('Could not send password link. Please try again later.')
      })
  }

  function confirmSuspendUser(userId: string) {
    showModal(<ConfirmSuspendDialog userId={userId} callback={suspendUser} />)
  }

  function suspendUser(userId: string) {
    terminateUser(userId)
      .then(() => {
        toast.pushSuccess('User account has been suspended')
      })
      .catch((error) => {
        errorHandler(error)
        toast.pushError('Could not suspend user account. Please try again later.')
      })
      .finally(() => setRefreshUsers(true))
  }

  function displayDialogError(message: string) {
    showModal(<ErrorDialog title="Error. Please try again later." message={message} />)
  }

  function clearFilters() {
    setPartnerFilter('')
    setStatusFilter(UserStatus.Unknown)
  }

  async function exportCsv() {
    setChangeInProgress(true)

    let partnerIdForApiCall = partnerId
    if (userGroup === GLOBAL_ADMIN) {
      partnerIdForApiCall = PARTNER_ID_FOR_ALL_USERS
      if (partnerFilter.length > 0) {
        partnerIdForApiCall = partnerList.find((element) => element.OrganizationName === partnerFilter)?.Id || ''
      }
    }

    const usersForExport: UserResponse[] = []
    let pageCount = 0
    let responseCount = 0

    do {
      await getUsersBySourcePartnerId(partnerIdForApiCall, USERS_PER_PAGE, pageCount, searchTerm, statusFilter).then(
        (response) => {
          usersForExport.push(...response)
          responseCount = response.length
        }
      )
      pageCount++
    } while (responseCount === USERS_PER_PAGE)
    setChangeInProgress(false)

    const options = {
      filename: 'partner-management.csv',
      showLabels: true,
      headers: ['First Name', 'Last Name', 'Email', 'User Group', 'Partner', 'Status'],
    }
    new ExportToCsv(options).generateCsv(formatUsersForExport(usersForExport))
  }

  return (
    <AdminLayout>
      <div className="md:pl-64">
        {changeInProgress && <LoadingIndicator />}
        <div className="flex flex-col max-w-5xl px-5 pt-1">
          <UserHeader
            partnerId={partnerId}
            userCount={totalUsers}
            openUserDialog={openUserDialog}
            exportCsv={exportCsv}
          />
          <main className="flex-1">
            <div className="flex justify-start">
              <FilterDropdown
                title="Status"
                listItems={[UserStatus.Active, UserStatus.Suspended]}
                selectedItem={statusFilter}
                setSelectedItem={(value) => setStatusFilter(value as UserStatus)}
              />
              {userGroup === GLOBAL_ADMIN && (
                <FilterDropdown
                  title="Partner"
                  listItems={partnerList.map((element) => element.OrganizationName)}
                  selectedItem={partnerFilter}
                  setSelectedItem={setPartnerFilter}
                />
              )}
              {(partnerFilter.length > 0 || statusFilter.length > 0) && (
                <div className="flex items-center text-xs text-black-50 cursor-pointer" onClick={clearFilters}>
                  Clear All
                </div>
              )}
              <div className="flex grow items-center justify-end ml-4">
                <SearchInput value={searchTerm} setSearchTerm={setSearchTerm} />
              </div>
            </div>
            <div className="flex items-center justify-end mt-8">
              <ItemsLabel itemCount={totalUsers} />
            </div>
            <div className="px-4 sm:px-6 md:px-0">
              {userData?.length > 0 ? (
                <UserTable users={userData} userGroup={userGroup} />
              ) : (
                !changeInProgress && 'No records to display'
              )}
            </div>
            {totalUsers > USERS_PER_PAGE && (
              <Pagination totalItems={totalUsers} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            )}
          </main>
        </div>
      </div>
      {modal}
    </AdminLayout>
  )
}
