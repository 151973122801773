import { datadogLogs } from '@datadog/browser-logs'
import { errorHandler } from './helpers'

export function initLogger(): void {
  if (
    process.env.REACT_APP_DD_CLIENT_TOKEN &&
    process.env.REACT_APP_DD_SITE &&
    process.env.REACT_APP_DD_SERVICE &&
    process.env.REACT_APP_DD_ENV
  ) {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
      site: process.env.REACT_APP_DD_SITE,
      service: process.env.REACT_APP_DD_SERVICE,
      env: process.env.REACT_APP_DD_ENV,
      forwardErrorsToLogs: true,
      sampleRate: 100,
    })
  } else {
    errorHandler('datadog could not be initialized')
  }
}

export const logger = datadogLogs.logger
