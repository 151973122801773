import { ReactElement } from 'react'
import { Modal } from './Modal'
import { useModalContext } from '../../contexts/modalContext'

interface Props {
  message: string
  title: string
}

export function ErrorDialog({ message, title }: Props): ReactElement {
  const { closeModal } = useModalContext()

  return (
    <Modal title={title} closeModal={closeModal} small={true}>
      <div className="flex-1 text-black-50 mt-1">{message}</div>
    </Modal>
  )
}
