import { MouseEvent, ReactElement, useEffect, useState } from 'react'
import { HorizontalInput } from './HorizontalInput'
import { UserResponse } from '../../models/users'

interface Props {
  id: string
  label: string
  listItems: UserResponse[]
  name: string
  disabled?: boolean
  placeholder?: string
  type?: string
  value?: string
  onChange: (value: string) => void
  setSelected: (value: UserResponse | undefined) => void
}

export function AutocompleteInput({
  id,
  label,
  listItems,
  name,
  disabled = false,
  placeholder,
  type = 'text',
  value,
  onChange,
  setSelected,
}: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [itemSelected, setItemSelected] = useState(false)

  useEffect(() => {
    if (value && value?.length > 0 && !itemSelected && !disabled && listItems.length > 1) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
      setItemSelected(false)
    }
  }, [value, listItems])

  function listItemSelected(e: MouseEvent<HTMLLIElement>) {
    setSelected(listItems.find((element) => element.Id === e.currentTarget.getAttribute('value')))
    setItemSelected(true)
  }

  return (
    <div className="relative">
      <HorizontalInput
        label={label}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        type={type}
        onChange={onChange}
      />
      {isOpen && (
        <ul className="absolute top-auto right-0 bg-white rounded-lg w-48 drop-shadow-xl">
          {listItems.map((listItem) => (
            <li
              key={listItem.Id}
              value={listItem.Id}
              className="block px-4 py-3 hover:bg-black-10 text-sm truncate cursor-pointer"
              onClick={listItemSelected}
            >
              {listItem.Email}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
