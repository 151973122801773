import { ReactElement } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { USERS_PER_PAGE } from '../../constants/common'

interface Props {
  currentPage: number
  totalItems: number
  setCurrentPage: (page: number) => void
}

export function Pagination({ currentPage, totalItems, setCurrentPage }: Props): ReactElement {
  const pages = Array.from(Array(Math.ceil(totalItems / USERS_PER_PAGE)).keys())

  function onPrevious() {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  function onNext() {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1)
    }
  }

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{USERS_PER_PAGE * currentPage + 1}</span> to{' '}
            <span className="font-medium">
              {USERS_PER_PAGE * currentPage + USERS_PER_PAGE > totalItems
                ? totalItems
                : USERS_PER_PAGE * currentPage + USERS_PER_PAGE}
            </span>{' '}
            of <span className="font-medium">{totalItems}</span> results
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <div className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" onClick={onPrevious} />
            </div>
            {pages.map((page) => (
              <div
                key={page}
                aria-current="page"
                onClick={() => setCurrentPage(page)}
                className={`z-10 ${
                  page === currentPage
                    ? 'bg-forteorange-30 border-forteorange-100 text-forteorange-100'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                }  relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer`}
              >
                {page + 1}
              </div>
            ))}
            <div className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer">
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" onClick={onNext} />
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
