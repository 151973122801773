import { ReactElement, ReactNode, Fragment, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuthContext } from '../contexts/authContext'
import { useModalContext } from '../contexts/modalContext'
import { Dialog, Transition } from '@headlessui/react'
import { NavItem } from '../models/profile'
import { VerticalNavigation } from '../components/global/VerticalNavigation'
import forteLogo from '../assets/forte-logo.svg'
import { GLOBAL_ADMIN, LocalStorageItem } from '../constants/common'
import { ErrorDialog } from '../components/global/ErrorDialog'

interface Props {
  children: ReactNode
}

export function AdminLayout({ children }: Props): ReactElement {
  const { signOut } = useAuthContext()
  const { modal, showModal } = useModalContext()
  const location = useLocation()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [userGroup, setUserGroup] = useState('')
  const [navItems, setNavItems] = useState<NavItem[]>([])

  useEffect(() => {
    setUserGroup(localStorage.getItem(LocalStorageItem.Scope) || '')
    setNavItems([
      {
        current: location.pathname.includes('/users'),
        href: '/users',
        name: 'Users',
      },
    ])
  }, [])

  useEffect(() => {
    if (userGroup === GLOBAL_ADMIN) {
      setNavItems([
        { current: location.pathname.includes('/partners'), href: '/partners', name: 'Partners' },
        ...navItems,
      ])
    }
  }, [userGroup])

  function showVersion() {
    showModal(<ErrorDialog title="Version" message={process.env.REACT_APP_VERSION || ''} />)
  }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}></Dialog>
      </Transition.Root>

      <div className="bg-gray-100 hidden md:flex md:w-60 md:flex-col md:fixed md:inset-y-0 p-6">
        <div className="flex-1 flex flex-col overflow-y-auto">
          <div className="flex flex-row items-center">
            <div className="flex mb-10">
              <img className="w-32" src={forteLogo} onClick={showVersion} />
            </div>
          </div>
          <div className="p-2">
            <VerticalNavigation navItems={navItems} />
          </div>
        </div>
        <div>
          <button className="text-black-50" onClick={signOut}>
            Sign Out
          </button>
        </div>
      </div>
      {children}
      {modal}
    </div>
  )
}
