import { ReactElement } from 'react'
import { Ellipsis } from '../global/Ellipsis'
import { UserTableData } from '../../models/users'
import { GLOBAL_ADMIN } from '../../constants/common'

interface Props {
  users: UserTableData[]
  userGroup: string
}

export function UserTable({ users, userGroup }: Props): ReactElement {
  const columns = ['Name', 'Email']

  return (
    <table className="min-w-full divide-y divide-black-10">
      <thead>
        <tr>
          {columns.map((columnName) => (
            <th key={columnName} scope="col" className="px-6 py-3 text-sm font-normal text-left text-black-50">
              {columnName}
            </th>
          ))}
          {userGroup === GLOBAL_ADMIN && (
            <th scope="col" className="px-6 py-3 text-sm font-normal text-left text-black-50">
              Partner
            </th>
          )}
          <th scope="col" className="px-6 py-3 text-sm font-normal text-left text-black-50">
            Status
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-black-10">
        {users.map((user) => (
          <tr
            key={user.id}
            className="hover:bg-black-5 cursor-pointer group"
            onClick={() => user.actions.length > 0 && user.actions[0].callback(user.id)}
          >
            <td className="px-6 py-5 text-sm font-normal text-black-100 align-top whitespace-nowrap">{user.name}</td>
            <td className="px-6 py-5 text-sm font-normal text-black-100 align-top whitespace-nowrap">{user.email}</td>
            {userGroup === GLOBAL_ADMIN && (
              <td className="px-6 py-5 text-sm font-normal text-black-100 align-top whitespace-nowrap">
                {user.partner}
              </td>
            )}
            <td className="px-6 py-5 text-sm font-normal text-black-100 align-top whitespace-nowrap">
              {user.active ? 'Active' : 'Suspended'}
            </td>
            <td className="opacity-0 group-hover:opacity-100" onClick={(e) => e.stopPropagation()}>
              <Ellipsis menuActions={user.actions} id={user.id} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
