import { PartnerListResponse, PartnerRequest, PartnerResponse } from '../models/partners'
import { apiRequest } from '../utils/axios'

export async function getPartners(limit: number, page: number, searchTerm?: string): Promise<PartnerResponse[]> {
  return apiRequest('GET', `/partners?limit=${limit}&page=${page}&search=${searchTerm}`)
}

export async function getPartnerById(partnerId: string): Promise<PartnerResponse> {
  return apiRequest('GET', `/partners?id=${partnerId}&limit=1&page=0&search=`).then((data) => data[0])
}

export async function savePartner(partnerData: PartnerRequest, url: string): Promise<void> {
  return apiRequest('POST', `/partners?returnUrl=${url}`, partnerData)
}

export async function updatePartner(partnerData: PartnerRequest): Promise<void> {
  return apiRequest('PUT', '/partners', partnerData)
}

export async function getPartnerList(): Promise<PartnerListResponse[]> {
  return apiRequest('GET', '/partner-list')
}
