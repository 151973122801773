import { ReactElement, KeyboardEvent, useState } from 'react'

interface Props {
  id: string
  label: string
  name: string
  autoComplete?: string
  disabled?: boolean
  error?: string
  placeholder?: string
  type?: string
  value?: string
  onChange: (value: string) => void
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void
  onBlur?: () => void
}

export function VerticalInput({
  id,
  label,
  name,
  autoComplete,
  disabled = false,
  error = '',
  placeholder,
  type = 'text',
  value,
  onChange,
  onKeyDown,
  onBlur,
}: Props): ReactElement {
  const [showLabel, setShowLabel] = useState(false)

  function localOnChange(newValue: string) {
    setShowLabel(newValue.length > 0)
    onChange(newValue)
  }

  return (
    <>
      <div
        className={`flex-start ${
          error.length > 0
            ? 'shadow-[0_2px_0_rgba(237,74,68,1.0)]'
            : 'shadow-[0_2px_0_rgba(0,0,0,0.05)] hover:shadow-[0_2px_0_rgba(0,0,0,0.50)] focus-within:shadow-[0_2px_0_rgba(0,0,0,1.0)]'
        }`}
      >
        {showLabel && <label className="flex text-black-50 text-xs">{label}</label>}
        <input
          name={name}
          id={id}
          type={type}
          value={value}
          autoComplete={autoComplete}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => localOnChange(e.target.value)}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          className={`${disabled ? 'text-black-50' : 'text-black-100'} text-sm border-none focus:ring-0 w-full`}
        />
      </div>
      <div className="text-forteorange-100 text-xs mt-1">{error}</div>
    </>
  )
}
