import { ReactElement, useEffect, useState, useRef } from 'react'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ChevronDownIcon } from '@heroicons/react/outline'

interface Props {
  listItems: string[]
  selectedItem: string
  title: string
  setSelectedItem: (value: string) => void
}

export function FilterDropdown({ listItems, selectedItem, title, setSelectedItem }: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  const filterRef = useRef(null)

  useOnClickOutside(filterRef, () => setIsOpen(false))

  useEffect(() => {
    setIsOpen(false)
  }, [listItems])

  return (
    <div
      ref={filterRef}
      className={`flex relative mr-2 border rounded-full hover:bg-black-5 px-3 py-1 cursor-pointer ${
        isOpen || selectedItem.length > 0 ? 'border-black-100' : 'border-black-10'
      }`}
      onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
    >
      <div className="flex whitespace-nowrap items-center text-sm">
        {selectedItem.length > 0 ? selectedItem : title}
        <ChevronDownIcon className="ml-2 h-4 w-4 text-black-60" />
      </div>
      {isOpen && (
        <div className="absolute top-9 left-0 bg-white rounded w-48 drop-shadow-xl">
          {listItems.map((listItem) => (
            <button
              key={listItem}
              value={listItem}
              className="px-4 py-3 hover:bg-black-5 text-sm truncate cursor-pointer w-full text-left"
              onClick={(e) => setSelectedItem((e.target as HTMLElement).innerText)}
            >
              {listItem}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
