import { RefObject, useEffect } from 'react'

type Event = MouseEvent | TouchEvent

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event, element: T) => void
): void {
  useEffect(() => {
    function listener(event: Event) {
      const element = ref?.current

      // Do nothing if clicking ref's element or descendent elements
      if (!element || element.contains((event?.target as Node) || null)) {
        return
      }

      handler(event, element)
    }

    document.addEventListener(`mousedown`, listener)
    document.addEventListener(`touchstart`, listener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
      document.removeEventListener(`touchstart`, listener)
    }
  }, [ref, handler])
}
