import { ReactElement, ReactNode, useCallback, useState } from 'react'
import { createContext } from '../utils/contextHelper'
import { ToastContainer, ToastContainerProps } from '../components/global/Toast/ToastContainer'
import { TOAST_INTERVAL_DEFAULT } from '../constants/common'

export type ToastProviderProps = {
  children: ReactNode
} & ToastContainerProps

type ToastMessageType = 'info' | 'success' | 'warning' | 'error' | 'default'

export type Toast = {
  id: string
  lifetime: number
  message: string | ReactNode
  type?: ToastMessageType
  header?: string
}

export interface ToastContextState {
  data: Toast[]
  pushError: (message: string, lifetime?: number) => void
  pushWarning: (message: string, lifetime?: number) => void
  pushSuccess: (message: string, lifetime?: number) => void
  pushInfo: (message: string, lifetime?: number) => void
  push: (message: string, type: ToastMessageType, lifetime?: number) => void
  pushCustom: (message: string | ReactNode, lifetime: number) => void
  remove: (id: string) => void
}

const [Provider, useToastContext] = createContext<ToastContextState>(module.filename)

export { useToastContext }

export function ToastProvider({ children, variant }: ToastProviderProps): ReactElement {
  const [data, setData] = useState<Array<Toast>>([])

  const push = useCallback(
    (message: string, type: ToastMessageType, lifetime?: number) => {
      if (message) {
        const new_item: Toast = {
          id: `toast-${Math.floor(Math.random() * 100)}`,
          message,
          type,
          lifetime: lifetime ? lifetime : TOAST_INTERVAL_DEFAULT,
        }

        setData((prevState) => [...prevState, new_item])
      }
    },
    [setData, data]
  )

  const pushCustom = useCallback(
    (message: string | ReactNode, lifetime?: number) => {
      if (message) {
        const new_item: Toast = {
          id: `toast-${Math.floor(Math.random() * 100)}`,
          message: message,
          lifetime: lifetime ? lifetime : TOAST_INTERVAL_DEFAULT,
          type: undefined,
        }

        setData((prevState) => [...prevState, new_item])
      }
    },
    [setData, data]
  )

  const pushError = useCallback((message: string, lifetime?: number) => push(message, 'error', lifetime), [push])
  const pushWarning = useCallback((message: string, lifetime?: number) => push(message, 'warning', lifetime), [push])
  const pushSuccess = useCallback((message: string, lifetime?: number) => push(message, 'success', lifetime), [push])
  const pushInfo = useCallback((message: string, lifetime?: number) => push(message, 'info', lifetime), [push])

  const ToastProviderValues = useCallback(() => {
    return {
      data,
      pushError,
      pushWarning,
      pushSuccess,
      pushInfo,
      push,
      pushCustom,

      async remove(id: string) {
        setData((prevState) => prevState.filter((e) => e.id !== id))
      },
    }
  }, [data, setData, pushError, pushWarning, pushSuccess, pushInfo, push, pushCustom])

  return (
    <Provider value={ToastProviderValues()}>
      <ToastContainer variant={variant} />
      {children}
    </Provider>
  )
}
