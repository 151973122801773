import { UserExportData, UserResponse } from '../models/users'

export function errorHandler(error: unknown): void {
  console.error(error) // eslint-disable-line no-console
}

export function getCookie(key: string): string {
  const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`)
  return b ? b.pop() || '' : ''
}

export function deleteCookie(key: string): void {
  document.cookie = `${key}=; Max-Age=0; path=/; domain=${location.hostname}`
}

export function formatUsersForExport(users: UserResponse[]): UserExportData[] {
  const exportUsers: UserExportData[] = []
  users.map((user) => {
    exportUsers.push({
      firstName: user.FirstName,
      lastName: user.LastName,
      email: user.Email,
      partner: user.OrganizationName,
      status: user.Active ? 'Active' : 'Suspended',
    })
  })
  return exportUsers
}
