import React, { ReactElement, Provider } from 'react'

export interface ContextProps {
  children: ReactElement
}

export function createContext<T>(filepath: string): [Provider<T | undefined>, () => T] {
  const context = React.createContext<T | undefined>(undefined)

  function useContext() {
    const ctx = React.useContext(context)

    if (!ctx) {
      throw new Error(`'${filepath}' must be inside a Provider with a value`)
    }

    return ctx
  }

  return [context.Provider, useContext]
}
