import { ReactElement } from 'react'
import { ForteH3 } from '@forte-fit/forte-storybook'
import { NavItem } from '../../models/profile'
import { Link } from 'react-router-dom'

interface Props {
  navItems: NavItem[]
}

export function VerticalNavigation({ navItems }: Props): ReactElement {
  return (
    <nav className="forte-component" aria-label="Sidebar">
      <div className="space-y-2">
        {navItems.map((item) => {
          return (
            <Link
              className={`group forte-component ${item.current ? 'block text-black-100' : 'block text-black-50'}`}
              key={item.name}
              to={item.href}
              aria-current={item.current ? 'page' : undefined}
            >
              <ForteH3>{item.name}</ForteH3>
            </Link>
          )
        })}
      </div>
    </nav>
  )
}
