import { ReactElement } from 'react'
import { Ellipsis } from '../global/Ellipsis'
import { PartnerTableData } from '../../models/partners'

interface Props {
  partners: PartnerTableData[]
}

export function PartnerTable({ partners }: Props): ReactElement {
  const columns = ['Partner', 'Primary Admin', 'Active Users']

  return (
    <table className="min-w-full divide-y divide-black-10">
      <thead>
        <tr>
          {columns.map((columnName) => (
            <th key={columnName} scope="col" className="px-6 py-3 text-sm font-normal text-left text-black-50">
              {columnName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-black-10">
        {partners.map((partner) => (
          <tr
            key={partner.id}
            className="hover:bg-black-5 cursor-pointer group"
            onClick={() => partner.actions.length > 0 && partner.actions[0].callback(partner.id)}
          >
            <td className="px-6 py-5 text-sm font-normal text-black-100 align-center whitespace-nowrap">
              {partner.name}
            </td>
            <td className="px-6 py-5 text-sm font-normal align-center whitespace-nowrap">
              <div className="text-black-100">{partner.adminName}</div>
              <div className="text-xs text-black-50">{partner.adminEmail}</div>
            </td>
            <td className="px-6 py-5 text-sm font-normal text-black-100 align-center whitespace-nowrap">
              {partner.userCount}
            </td>
            <td className="opacity-0 group-hover:opacity-100" onClick={(e) => e.stopPropagation()}>
              <Ellipsis menuActions={partner.actions} id={partner.id} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
