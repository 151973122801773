import { UserRequest, UserResponse } from '../models/users'
import { apiRequest } from '../utils/axios'
import { DEFAULT_USERS_BY_EMAIL_LIMIT } from '../constants/common'
import { ResetPasswordRequest } from '../models/authentication'
import { getTenantId } from './authentication'

export async function getUsersBySourcePartnerId(
  sourcePartnerId: string,
  limit: number,
  page: number,
  searchTerm?: string,
  status = ''
): Promise<UserResponse[]> {
  return apiRequest(
    'GET',
    `/users?sourcePartnerId=${sourcePartnerId}&limit=${limit}&page=${page}&search=${searchTerm}&status=${status}`
  )
}

export async function getUserById(id: string): Promise<UserResponse> {
  return apiRequest('GET', `/users?id=${id}&limit=1&page=0&search=&status=`).then((data) => data[0])
}

export async function getUsersByEmail(email: string, limit = DEFAULT_USERS_BY_EMAIL_LIMIT): Promise<UserResponse[]> {
  return apiRequest('GET', `/email-list?limit=${limit}&page=0&email=${email}`)
}

export async function saveUser(userData: UserRequest): Promise<string> {
  return apiRequest('POST', '/users', userData)
}

export async function updateUser(userData: UserRequest): Promise<string> {
  return apiRequest('PUT', '/users', userData)
}

export async function terminateUser(id: string): Promise<void> {
  return apiRequest('PATCH', `/users?id=${id}`)
}

export async function resetUserPassword(id: string): Promise<void> {
  return apiRequest('POST', `/password-reset?id=${id}`)
}

export async function resetAdminPassword(data: ResetPasswordRequest): Promise<void> {
  return apiRequest('POST', `/admin-password-reset`, data)
}

export async function sendResetPasswordEmailRequest(email: string, url: string): Promise<void> {
  const tenantId = await getTenantId()
  return apiRequest('GET', `/admin-password-reset/${email}?returnUrl=${url}&tenantId=${tenantId}`)
}
