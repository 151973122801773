import { ReactElement, useState } from 'react'
import { SearchIcon } from '@heroicons/react/outline'
import { REGEX_FILTER_NO_SPECIAL_CHARS } from '../../constants/common'

interface Props {
  value?: string
  setSearchTerm: (value: string) => void
}

export function SearchInput({ value = '', setSearchTerm }: Props): ReactElement {
  const [searchValue, setSearchValue] = useState(value)

  function onChange(newValue: string) {
    setSearchValue(newValue.replace(REGEX_FILTER_NO_SPECIAL_CHARS, ''))
    if (newValue.length === 0) {
      setSearchTerm('')
    }
  }

  function onKeyPress(key: string) {
    if (key === 'Enter') {
      setSearchTerm(searchValue)
    }
  }

  return (
    <div className="inline-flex items-center shadow-[0_2px_0_rgba(0,0,0,0.05)] hover:shadow-[0_2px_0_rgba(0,0,0,0.50)] focus-within:shadow-[0_2px_0_rgba(0,0,0,1.0)]">
      <SearchIcon className="w-4 h-4 text-black-60 rotate-90" />
      <input
        className="text-sm border-none focus:ring-0 pr-0"
        type="text"
        name="search"
        id="search"
        value={searchValue}
        onChange={(e) => onChange(e.target.value)}
        onKeyPress={(e) => onKeyPress(e.key)}
        placeholder="Keyword Search"
      />
    </div>
  )
}
