import { ReactElement, useEffect } from 'react'
import { Toast } from '../../../contexts/toastContext'
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline'

const VARIANTS = {
  info: {
    base: 'bg-white border-blue-500',
    name: 'Info',
    icon: '',
  },

  error: {
    base: 'bg-white border-red-500 ',
    name: 'Error',
    icon: <ExclamationIcon className="h-full w-5 ml-2 text-black-60" />,
  },

  warning: {
    base: 'bg-white border-yellow-500',
    name: 'Warning',
    icon: '',
  },

  success: {
    base: 'bg-white border-green-500',
    name: 'Success',
    icon: <CheckCircleIcon className="h-full w-5 ml-2 text-black-60" />,
  },
  default: {
    base: 'bg-white border-gray-600 ',
    name: 'Default',
    icon: '',
  },
}

export type ToastMessage = {
  id: string
  lifetime?: number
  variant?: keyof typeof VARIANTS
  onRemove?: (id: string) => void
} & Toast

export function ToastMessage({ id, message, lifetime, onRemove, type = 'default' }: ToastMessage): ReactElement {
  const variantType = VARIANTS[type]

  useEffect(() => {
    if (lifetime && onRemove) {
      const timer = setTimeout(() => {
        onRemove(id)
      }, lifetime)
      return () => clearTimeout(timer)
    }
  }, [lifetime])

  return (
    <div
      className={`flex w-full visible flex-row shadow-lg border-t-4 rounded-md duration-100 cursor-pointer transform transition-all hover:scale-102 max-h-40 ${variantType.base}`}
    >
      {variantType.icon}
      <div className="flex flex-row p-2 flex-no-wrap w-full">
        <div className="flex flex-col flex-no-wrap px-1 w-full">
          <div className="flex my-auto text-gray-600 text-sm select-none">{message}</div>
        </div>
        <div onClick={() => onRemove && onRemove(id)} className="mr-3 pb-1 text-center text-2xl">
          ×
        </div>
      </div>
    </div>
  )
}
