import { ReactElement, useEffect, useState } from 'react'
import { useModalContext } from '../../contexts/modalContext'
import { Button } from '@forte-fit/forte-storybook'
import { HorizontalDropdown } from '../global/HorizontalDropdown'
import { HorizontalInput } from '../global/HorizontalInput'
import { LoadingIndicator } from '../global/LoadingIndicator'
import { Modal } from '../global/Modal'
import { PartnerListResponse } from '../../models/partners'
import { UserRequest } from '../../models/users'
import { errorHandler } from '../../utils/helpers'
import { getPartnerList } from '../../services/partners'
import { getUserById, saveUser, updateUser } from '../../services/users'
import { LocalStorageItem, REGEX_EMAIL_VALIDATION, UserStatus } from '../../constants/common'

interface Props {
  userId?: string
  isGlobalAdmin?: boolean
  setRefreshUsers: (value: boolean) => void
  showError: (message: string) => void
}

export function UserDialog({ userId, isGlobalAdmin = false, setRefreshUsers, showError }: Props): ReactElement {
  const { closeModal } = useModalContext()

  const [title, setTitle] = useState('New User')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [userStatus, setUserStatus] = useState(UserStatus.Active)
  const [partner, setPartner] = useState('')
  const [partnerList, setPartnerList] = useState<PartnerListResponse[]>([])
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false)
  const [changeInProgress, setChangeInProgress] = useState(false)
  useEffect(() => {
    getPartnerList().then(setPartnerList).catch(errorHandler)
    if (userId) {
      setChangeInProgress(true)
      getUserById(userId)
        .then((result) => {
          setFirstName(result.FirstName)
          setLastName(result.LastName)
          setEmail(result.Email)
          setPartner(result.OrganizationName)
          setUserStatus(result.Active ? UserStatus.Active : UserStatus.Suspended)
        })
        .catch((error) => {
          errorHandler(error)
          showError(error.toString())
        })
        .finally(() => setChangeInProgress(false))
      setSaveButtonEnabled(true)
    }
  }, [userId])

  useEffect(() => {
    if (firstName?.length > 0 || lastName?.length > 0) {
      setTitle(`${firstName} ${lastName}`)
    } else {
      setTitle('New User')
    }
    setSaveButtonEnabled(shouldSaveBeEnabled())
  }, [firstName, lastName, email, partner])

  function shouldSaveBeEnabled(): boolean {
    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      REGEX_EMAIL_VALIDATION.test(email) &&
      (partner !== '' || !isGlobalAdmin)
    )
  }

  async function onSave() {
    setChangeInProgress(true)

    const userRequest: UserRequest = {
      UserName: email,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      PartnerId:
        partnerList.find((element) => element.OrganizationName === partner)?.Id ||
        localStorage.getItem(LocalStorageItem.PartnerId) ||
        '',
      Id: userId,
      Active: userStatus == UserStatus.Active,
    }

    const saveOrUpdate = userId?.length ? updateUser(userRequest) : saveUser(userRequest)

    saveOrUpdate
      .then(() => {
        closeModal()
      })
      .catch((error) => {
        errorHandler(error)
        showError(error.response?.data?.errorMessage ? error.response.data.errorMessage : error.toString())
      })
      .finally(async () => {
        setRefreshUsers(true)
        setChangeInProgress(false)
      })
  }

  return (
    <Modal title={title} closeModal={closeModal}>
      <div className="flex-1">
        {changeInProgress && <LoadingIndicator />}
        <div className="grid grid-cols-1">
          <HorizontalDropdown
            items={[
              { label: UserStatus.Active, value: UserStatus.Active },
              { label: UserStatus.Suspended, value: UserStatus.Suspended },
            ]}
            label="Status"
            id="userStatus"
            value={userStatus}
            onChange={(value) => setUserStatus(value as UserStatus)}
          />
          <HorizontalInput
            label="First Name"
            name="firstName"
            id="firstName"
            value={firstName}
            type="text"
            placeholder="Enter First Name"
            onChange={setFirstName}
          />
          <HorizontalInput
            label="Last Name"
            name="lastName"
            id="lastName"
            value={lastName}
            type="text"
            placeholder="Enter Last Name"
            onChange={setLastName}
          />
          <HorizontalInput
            label="Email"
            name="email"
            id="email"
            value={email}
            type="text"
            placeholder="Enter Email"
            onChange={setEmail}
          />
          {isGlobalAdmin && (
            <HorizontalDropdown
              items={partnerList.map((partnerItem) => ({
                label: partnerItem.OrganizationName,
                value: partnerItem.OrganizationName,
              }))}
              label="Partner"
              id="partner"
              value={partner}
              onChange={setPartner}
            />
          )}
        </div>
        <div className="flex items-end justify-start space-x-2 mt-10 font-sans">
          <fieldset disabled={!saveButtonEnabled || changeInProgress}>
            <div className={saveButtonEnabled && !changeInProgress ? 'opacity-100' : 'opacity-25'}>
              <Button onClick={onSave}>Save</Button>
            </div>
          </fieldset>
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </div>
    </Modal>
  )
}
