import { ReactElement } from 'react'
import spinnerIcon from '../../assets/spinner-icon.svg'

interface Props {
  buttonLabel: string
  disabled: boolean
  isLoading: boolean
  onClick: () => void
}

export function LoginButton({ buttonLabel, disabled, isLoading, onClick }: Props): ReactElement {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${
        disabled && !isLoading
          ? 'text-black-20 bg-black-5 hover:bg-black-5 focus:ring-black-5'
          : 'text-white bg-forteorange-100 hover:bg-forteorange-80 focus:ring-forteorange-100'
      } inline-flex items-center text-xs font-bold tracking-wider uppercase border rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent shadow-sm px-6 py-3 w-full justify-center`}
    >
      {isLoading ? <img className="animate-spin h-4" src={spinnerIcon} /> : buttonLabel}
    </button>
  )
}
