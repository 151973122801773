import { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AccessTokenRequest } from '../models/authentication'
import { ContextProps, createContext } from '../utils/contextHelper'
import { LocalStorageItem } from '../constants/common'
import { getAuthToken } from '../services/authentication'
import { deleteCookie, errorHandler } from '../utils/helpers'
import { getProfile } from '../services/profile'

interface AuthContextState {
  authorized: boolean
  authInProgress: boolean
  error: string
  signOutInProgress: boolean
  authorize: (authData: AccessTokenRequest) => void
  signOut: () => void
}

const [Provider, useAuthContext] = createContext<AuthContextState>(module.filename)

export { useAuthContext }

export function AuthProvider({ children }: ContextProps): ReactElement {
  const navigate = useNavigate()

  const [authorized, setAuthorized] = useState(false)
  const [error, setError] = useState('')
  const [authInProgress, setAuthInProgress] = useState(false)
  const [signOutInProgress, setSignOutInProgress] = useState(false)

  useEffect(() => {
    if (
      window.location.pathname.indexOf('reset-password') == -1 &&
      !localStorage.getItem(LocalStorageItem.Username)?.length
    ) {
      navigate('/')
    }
  }, [])

  function authorize(authData: AccessTokenRequest) {
    setAuthInProgress(true)
    getAuthToken(authData)
      .then((response) => {
        localStorage.setItem(LocalStorageItem.Username, response.userName)
        getProfile(response.userName)
          .then((profileResponse) => {
            localStorage.setItem(LocalStorageItem.Scope, profileResponse.UserGroup)
            localStorage.setItem(LocalStorageItem.PartnerId, profileResponse.Partner_Id)
            localStorage.setItem(LocalStorageItem.Token, response.access_token)
          })
          .then(() => {
            setError('')
            setAuthorized(true)
          })
      })
      .catch((e) => {
        errorHandler(e)
        setError(e?.response?.data)
        setAuthorized(false)
      })
      .finally(() => setAuthInProgress(false))
  }

  function signOut() {
    setSignOutInProgress(true)
    localStorage.removeItem(LocalStorageItem.Username)
    localStorage.removeItem(LocalStorageItem.Scope)
    localStorage.removeItem(LocalStorageItem.PartnerId)
    localStorage.removeItem(LocalStorageItem.Token)
    deleteCookie('token')
    setAuthorized(false)
    setSignOutInProgress(false)
    navigate('/')
  }

  return (
    <Provider
      value={{
        authorized,
        authInProgress,
        error,
        signOutInProgress,
        authorize,
        signOut,
      }}
    >
      {children}
    </Provider>
  )
}
