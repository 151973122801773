import { ReactElement, KeyboardEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ResetPasswordRequest } from '../models/authentication'
import { ForteH3 } from '@forte-fit/forte-storybook'
import { LoginButton } from '../components/login/LoginButton'
import { VerticalInput } from '../components/global/VerticalInput'
import forteLogo from '../assets/forte-logo.svg'
import { resetAdminPassword } from '../services/users'
import { useToastContext } from '../contexts'

export function ResetPasswordPage(): ReactElement {
  const token = new URLSearchParams(useLocation().search).get('token')

  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [resetInProgress, setResetInProgress] = useState(false)
  const [resetEnabled, setResetEnabled] = useState(false)
  const toast = useToastContext()
  useEffect(() => {
    setResetEnabled(isPasswordEntered())
  }, [password])

  async function onSubmit(): Promise<void> {
    setResetInProgress(true)
    const resetPasswordData: ResetPasswordRequest = {
      TokenId: token || '',
      NewPassword: password,
    }

    await resetAdminPassword(resetPasswordData)
      .then(() => navigate('/'))
      .catch((e) => {
        toast.pushError(e.response.data.message)
      })
      .finally(() => setResetInProgress(false))
  }

  function isPasswordEntered(): boolean {
    return password.length > 3
  }

  async function onPressEnter(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      if (resetEnabled) {
        await onSubmit()
      }
    }
  }

  return (
    <div className="h-screen flex flex-wrap flex-row place-content-center">
      <div className="basis-80">
        <div className="mb-10 justify-center">
          <img className="mx-auto w-48" src={forteLogo} />
        </div>
        <div className="flex mb-10 place-content-center">
          <ForteH3>Password reset</ForteH3>
        </div>
        <div className="m-3">
          <VerticalInput
            label="Password"
            name="password"
            id="password"
            value={password}
            type="password"
            autoComplete="current-password"
            placeholder="Password"
            onChange={setPassword}
            onKeyDown={onPressEnter}
          />
        </div>
        <div className="mt-5 ml-3 mr-3">
          <LoginButton onClick={onSubmit} buttonLabel="Reset" disabled={!resetEnabled} isLoading={resetInProgress} />
        </div>
      </div>
    </div>
  )
}
