import { ReactElement } from 'react'
import { CSVBoxButton } from '@csvbox/react'
import { Ellipsis } from '../global/Ellipsis'
import { ForteH1, Button } from '@forte-fit/forte-storybook'
import { MenuAlt2Icon } from '@heroicons/react/outline'
import { logger } from '../../utils/datadog'
import { LocalStorageItem } from '../../constants/common'

interface Props {
  partnerId: string
  userCount: number
  openUserDialog: () => void
  exportCsv: () => void
}

export function UserHeader({ partnerId, userCount, openUserDialog, exportCsv }: Props): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onImportCsvBox(result: boolean, data: any) {
    if (result) {
      logger.info('csvbox import success')
      logger.info(data.row_success + ' rows uploaded')
    } else {
      logger.info('csvbox import failed')
    }
  }

  function onRenderCsvBox(importLaunch: () => void): ReactElement {
    return (
      <div className="inline-block ml-4">
        <Ellipsis
          menuActions={[
            { label: 'Import Users', callback: importLaunch },
            { label: 'Export Users', disabled: userCount <= 0, callback: exportCsv },
          ]}
        />
      </div>
    )
  }

  return (
    <div className="sticky top-0 z-10 flex flex-shrink-0">
      <button
        type="button"
        className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
      </button>
      <div className="flex justify-between flex-1 px-4 bg-white md:px-0">
        <div className="flex flex-1 py-6">
          <ForteH1>Users</ForteH1>
        </div>
        <div className="flex items-center ml-4 md:ml-6 font-sans">
          <Button onClick={openUserDialog}>User</Button>

          {partnerId && (
            <CSVBoxButton
              licenseKey={process.env.REACT_APP_CSVBOX_LICENSE_KEY}
              user={{
                user_id: localStorage.getItem(LocalStorageItem.Username),
                partner_id: partnerId,
                token: localStorage.getItem(LocalStorageItem.Token),
              }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onImport={(result: boolean, data: any) => onImportCsvBox(result, data)}
              render={(launch: () => void) => onRenderCsvBox(launch)}
            >
              Import
            </CSVBoxButton>
          )}
        </div>
      </div>
    </div>
  )
}
