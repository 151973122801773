import { ReactElement, useEffect, useState } from 'react'
import { useModalContext } from '../../contexts/modalContext'
import { AutocompleteInput } from '../global/AutocompleteInput'
import { Button } from '@forte-fit/forte-storybook'
import { HorizontalInput } from '../global/HorizontalInput'
import { LoadingIndicator } from '../global/LoadingIndicator'
import { Modal } from '../global/Modal'
import { PartnerRequest } from '../../models/partners'
import { UserResponse } from '../../models/users'
import { errorHandler } from '../../utils/helpers'
import { getUsersByEmail } from '../../services/users'
import { getPartnerById, savePartner, updatePartner } from '../../services/partners'
import { INPUT_FIELD_SEARCH_DELAY, REGEX_EMAIL_VALIDATION } from '../../constants/common'

interface Props {
  partnerId?: string
  setRefreshPartners: (value: boolean) => void
  showError: (message: string) => void
}

export function PartnerDialog({ partnerId, setRefreshPartners, showError }: Props): ReactElement {
  const { closeModal } = useModalContext()

  const [title, setTitle] = useState('New Partner')
  const [organizationName, setOrganizationName] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false)
  const [changeInProgress, setChangeInProgress] = useState(true)
  const [emailCandidates, setEmailCandidates] = useState<UserResponse[]>([])
  const [selectedUser, setSelectedUser] = useState<UserResponse>()

  useEffect(() => {
    if (partnerId) {
      setChangeInProgress(true)
      getPartnerById(partnerId)
        .then((result) => {
          setOrganizationName(result.OrganizationName)
          setEmail(result.Email)
          setFirstName(result.FirstName)
          setLastName(result.LastName)
        })
        .catch((error) => {
          errorHandler(error)
          showError(error.toString())
        })
        .finally(() => setChangeInProgress(false))
      setSaveButtonEnabled(true)
    } else {
      setChangeInProgress(false)
    }
  }, [partnerId])

  useEffect(() => {
    if (organizationName.length > 0) {
      setTitle(organizationName)
    } else {
      setTitle('New Partner')
    }
  }, [organizationName])

  useEffect(() => {
    setEmail(selectedUser?.Email || '')
    setFirstName(selectedUser?.FirstName || '')
    setLastName(selectedUser?.LastName || '')
  }, [selectedUser])

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      getUsersByEmail(email)
        .then((response) => setEmailCandidates(response))
        .catch(errorHandler)
        .finally(() => setChangeInProgress(false))
    }, INPUT_FIELD_SEARCH_DELAY)

    return () => clearTimeout(searchDelay)
  }, [email])

  useEffect(() => {
    setSaveButtonEnabled(shouldSaveBeEnabled())
  }, [email, organizationName, firstName, lastName])

  function onChangeEmail(value: string) {
    setEmail(value)
    if (value.length > 0) {
      setChangeInProgress(true)
      setEmailCandidates([])
    }
  }

  function shouldSaveBeEnabled(): boolean {
    return (
      organizationName.length > 0 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      REGEX_EMAIL_VALIDATION.test(email)
    )
  }

  function onSave() {
    setChangeInProgress(true)
    const partnerRequest: PartnerRequest = {
      Id: partnerId,
      OrganizationName: organizationName,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
    }

    const url = `${window.location.origin}/reset-password`
    const saveOrUpdate = partnerId ? updatePartner(partnerRequest) : savePartner(partnerRequest, url)

    saveOrUpdate
      .then(() => closeModal())
      .catch((error) => {
        errorHandler(error)
        showError(error.toString())
      })
      .finally(() => {
        setRefreshPartners(true)
        setChangeInProgress(false)
      })
  }

  return (
    <Modal title={title} closeModal={closeModal}>
      <div className="flex-1">
        {changeInProgress && <LoadingIndicator />}
        <div className="grid grid-cols-1">
          <HorizontalInput
            label="Name"
            name="name"
            id="name"
            value={organizationName}
            type="text"
            placeholder="Enter Partner Name"
            onChange={setOrganizationName}
          />
        </div>
        <div className="mt-10 text-sm font-bold text-black-100">Primary Admin</div>
        <div className="grid grid-cols-1 gap-2">
          <AutocompleteInput
            label="Email"
            name="email"
            id="email"
            listItems={emailCandidates}
            value={email}
            type="email"
            placeholder="Enter Email"
            onChange={onChangeEmail}
            setSelected={setSelectedUser}
            disabled={partnerId !== undefined}
          />
          <HorizontalInput
            label="First Name"
            name="firstName"
            id="firstName"
            value={firstName}
            type="text"
            placeholder="Enter First Name"
            onChange={setFirstName}
          />
          <HorizontalInput
            label="Last Name"
            name="lastName"
            id="lastName"
            value={lastName}
            type="text"
            placeholder="Enter Last Name"
            onChange={setLastName}
          />
        </div>

        <div className="flex items-end justify-start space-x-2 mt-10 font-sans">
          <fieldset disabled={!saveButtonEnabled || changeInProgress}>
            <div className={saveButtonEnabled && !changeInProgress ? 'opacity-100' : 'opacity-25'}>
              <Button onClick={onSave}>Save</Button>
            </div>
          </fieldset>
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </div>
    </Modal>
  )
}
