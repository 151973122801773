import { ReactElement, useEffect, useState } from 'react'
import { useModalContext } from '../contexts/modalContext'
import { AdminLayout } from './AdminLayout'
import { ErrorDialog } from '../components/global/ErrorDialog'
import { ItemsLabel } from '../components/global/ItemsLabel'
import { LoadingIndicator } from '../components/global/LoadingIndicator'
import { Pagination } from '../components/global/Pagination'
import { PartnerDialog } from '../components/partners/PartnerDialog'
import { PartnerHeader } from '../components/partners/PartnerHeader'
import { PartnerResponse, PartnerTableData } from '../models/partners'
import { PartnerTable } from '../components/partners/PartnerTable'
import { SearchInput } from '../components/global/SearchInput'
import { errorHandler } from '../utils/helpers'
import { getPartners } from '../services/partners'
import { GLOBAL_ADMIN, LocalStorageItem, PARTNERS_PER_PAGE } from '../constants/common'

export function PartnersPage(): ReactElement {
  const { modal, showModal } = useModalContext()

  const [partners, setPartners] = useState<PartnerResponse[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPartners, setTotalPartners] = useState(0)
  const [refreshPartners, setRefreshPartners] = useState(true)
  const [changeInProgress, setChangeInProgress] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [userGroup, setUserGroup] = useState('')

  const partnerData: PartnerTableData[] = partners.map((partner) => {
    return {
      id: partner.Id,
      name: partner.OrganizationName,
      adminName: `${partner.FirstName} ${partner.LastName}`,
      adminEmail: partner.Email,
      userCount: partner.UserCount,
      actions: userGroup === GLOBAL_ADMIN ? [{ label: 'Edit', callback: editPartner }] : [],
    }
  })

  useEffect(() => {
    setUserGroup(localStorage.getItem(LocalStorageItem.Scope) || '')
  }, [])

  useEffect(() => {
    getPartnerData()
  }, [currentPage])

  useEffect(() => {
    if (refreshPartners) {
      getPartnerData()
      setRefreshPartners(false)
    }
  }, [refreshPartners])

  useEffect(() => {
    setCurrentPage(0)
    getPartnerData()
  }, [searchTerm])

  function getPartnerData() {
    setChangeInProgress(true)
    getPartners(PARTNERS_PER_PAGE, currentPage, searchTerm)
      .then((response) => {
        setPartners(response)
        if (response?.length > 0) {
          setTotalPartners(response[0].OverallCount)
        } else {
          setTotalPartners(0)
        }
      })
      .catch((error) => {
        errorHandler(error)
        showModal(<ErrorDialog title="Error. Please try again later." message={error.toString()} />)
      })
      .finally(() => setChangeInProgress(false))
  }

  function openPartnerDialog() {
    showModal(<PartnerDialog setRefreshPartners={setRefreshPartners} showError={displayDialogError} />)
  }

  function editPartner(partnerId: string) {
    showModal(
      <PartnerDialog partnerId={partnerId} setRefreshPartners={setRefreshPartners} showError={displayDialogError} />
    )
  }

  function displayDialogError(message: string) {
    showModal(<ErrorDialog title="Error. Please try again later." message={message} />)
  }

  return (
    <AdminLayout>
      <div className="md:pl-64">
        {changeInProgress && <LoadingIndicator />}
        <div className="flex flex-col max-w-5xl px-5 pt-1">
          <PartnerHeader openPartnerDialog={openPartnerDialog} userGroup={userGroup} />
          <main className="flex-1">
            <div className="flex items-center justify-end ml-4 md:ml-6">
              <SearchInput value={searchTerm} setSearchTerm={setSearchTerm} />
            </div>
            <div className="flex items-center justify-end ml-4 mt-8 -mb-8 md:ml-6">
              <ItemsLabel itemCount={totalPartners} />
            </div>
            <div className="py-6">
              <div className="px-4 sm:px-6 md:px-0">
                {partnerData?.length > 0 ? (
                  <PartnerTable partners={partnerData} />
                ) : (
                  !changeInProgress && 'No records to display'
                )}
              </div>
            </div>
            {totalPartners > PARTNERS_PER_PAGE && (
              <Pagination totalItems={totalPartners} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            )}
          </main>
        </div>
      </div>
      {modal}
    </AdminLayout>
  )
}
