import { ReactElement } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AuthProvider, ModalProvider, ToastProvider } from './contexts'
import { LoginPage, PartnersPage, UsersPage, ResetPasswordPage } from './pages'
import './App.css'

function App(): ReactElement {
  return (
    <Router>
      <AuthProvider>
        <ModalProvider>
          <ToastProvider>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/partners" element={<PartnersPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
            </Routes>
          </ToastProvider>
        </ModalProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
