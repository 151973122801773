import { ReactElement, useState } from 'react'
import { DotsVerticalIcon } from '@heroicons/react/outline'
import { MenuActions } from '../../models/ellipsis'

interface Props {
  menuActions: MenuActions[]
  id?: string
}

export function Ellipsis({ menuActions, id }: Props): ReactElement {
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <div
      className="relative"
      onClick={() => setShowDropdown(!showDropdown)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <button
        id="dropdownButton"
        className="hover:bg-gray-50 rounded-lg px-4 py-2.5 inline-flex items-center"
        type="button"
      >
        <DotsVerticalIcon className="w-5 h-5 text-black-60" />
      </button>

      {showDropdown && (
        <div id="dropdown" className="absolute top-30 right-0 z-10 w-44 bg-white rounded drop-shadow-xl">
          {menuActions.map((item) => (
            <button
              key={item.label}
              type="button"
              className={`py-3 px-3 w-full text-sm text-left hover:bg-black-5 ${item.disabled && 'text-black-50'}`}
              disabled={item.disabled}
              onClick={() => (id ? item.callback(id) : item.callback())}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
