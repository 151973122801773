import { AccessTokenRequest, AccessTokenResponse } from '../models/authentication'
import { apiRequest, externalApiRequest } from '../utils/axios'

export async function getTenantId(): Promise<string> {
  const urlParts = window.location.host.split('.')
  const domain = urlParts.filter((x) => x != urlParts[0]).join('.')
  let tenantId = ''
  try {
    const result = await externalApiRequest(
      'GET',
      `${process.env.REACT_APP_VR_API_ENDPOINT}/multitenancy/v1/tenant/findByUrl?url=${domain}&platform=WEBAPP`
    )
    tenantId = result.data?.tenantIdentifier ?? process.env.REACT_APP_FALLBACK_TENANT_ID
  } catch (e) {
    tenantId = process.env.REACT_APP_FALLBACK_TENANT_ID || ''
  }

  return tenantId
}

export async function getAuthToken(request: AccessTokenRequest): Promise<AccessTokenResponse> {
  const tenantId = await getTenantId()
  return apiRequest('POST', `/token?tenantId=${tenantId}`, request)
}
